import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch
} from 'react-router-dom';
// reactstrap components
import { Container } from "reactstrap";
import Navbar from './components/Navbars/Navbar';
import Header from './components/Headers/Header';
import Footer from './components/Footers/Footer';

import HomePage from './views/HomePage';
import AboutPage from './views/AboutPage';
import MenuPage from './views/MenuPage';


function App() {
  return (
    <>
      <div className="App">        
        <Header />
        <Container style={{minHeight:'500px'}}>
          <Router>
          <Navbar />
            <Switch>
              <Route exact path='/' component={HomePage}></Route>
              <Route exact path="/about" component={AboutPage}></Route>
              <Route exact path="/menu" component={MenuPage}></Route>
            </Switch>
          </Router>
        </Container>
        <Footer />
      </div>

    </>)
}

export default App;
