import React, { useState } from 'react';
import { data } from '../../datas/MenuData.js';
import MenuItem from './MenuItem';
import {
    Container,
    Col,
    Nav, NavItem, NavLink, Row
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTimes,  faAngleDoubleRight, faArrowRight, faHamburger} from '@fortawesome/free-solid-svg-icons';

export default function Menu() {

    const [selectedCategory, setCategory] = useState("1");
    const defaultNavItemStyle = "text-left navItem";
    const [sideNavbarStyle, setSideNavbarStyle] = useState("sidenav active"); 

    function GetNavItemStyle(i){
      return  i%2===0 && defaultNavItemStyle || defaultNavItemStyle + " stripeNavItem";
    }

   function scrollTop()
{
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

const toogleMenu = (e) =>{
   sideNavbarStyle === 'sidenav active' ?
        setSideNavbarStyle('sidenav') :
        setSideNavbarStyle('sidenav active');  
}

    return (

<>
<div className="menu-content">
    <div className="menu-bar">
    <div className="menu-icon">
     <FontAwesomeIcon icon={faHamburger} className="menuIcon" onClick={()=>{toogleMenu(); scrollTop()}} />
    </div> 
<aside className={sideNavbarStyle}>

<div className="sidenav__close-icon">
     <FontAwesomeIcon icon={faTimes} onClick={toogleMenu} />
</div>

<Nav vertical className="sidenav__list text-left">
    <h5 className="p-2 text-center">MENU</h5>
    {GetCategories()}
</Nav>


</aside>
</div>
<div className="menu-item-content">

            {CreateMenus(selectedCategory)}
</div>
</div>

</>
    );

    function GetCategories() {
        const list = data.category.map((x, i) => {
            return <NavItem key={i} className={GetNavItemStyle(i)}>
                <NavLink id={x.id} onClick={() => { setCategory(x.id); scrollTop(); toogleMenu() }}>{x.name}</NavLink>
            </NavItem>
        });
        return list;
    }

    function CreateMenus(id) {
        const currentCategory = data.category.find(x => x.id === id);
        return currentCategory &&
            <>
                <Container id={currentCategory.id}>
                   
                      
                        <h3 className="text-center text-warning">{currentCategory.name}</h3>
                        <p><i>{currentCategory.helpText}</i></p>
                        <b>{currentCategory.remarks}</b>
                   
                    {currentCategory.menu && <MenuItem data={currentCategory.menu} />}
                    <Col>
                        <br />
                        <i>{currentCategory.footnote}</i>
                    </Col>
                   
                </Container></> || "";


    }

}

