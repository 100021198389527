import React from "react";
import logo from '../../assets/img/Romas.PNG';
import { Row, Col, Container, NavLink } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

function Footer() {
    return (
        <footer className="footer-light">
            <Container>
                <Row className="footer-nav">
                    
                    <Col lg="12" md="12" xs="12">
                      
                                <NavLink className="text-center text-white"
                                    data-placement="bottom"
                                    href="https://www.facebook.com/Romas-Pizza-Grill-Curry-793056420709857/"
                                    target="_blank"
                                    title="Like us on Facebook"
                                >
                                   <FontAwesomeIcon icon={faFacebook} size="lg"/>
                                    <p className="d-lg-none">Facebook</p>
                                </NavLink>
                            

                        <b>We are located at :</b>
                        <p>
                            113 Main St, <br />
                           Beckley, WV 25801
                        </p>
                        <span><i className="fa fa-phone-square"/> (304) 254-9158 / (304) 254-9900</span>
                        <br/>
                        <br/>
                        <p className="text-mute">Roma's "Pizza and Grill"
                               <span className="copyright">
                                © {new Date().getFullYear()}
                            </span>
                        </p>

                    </Col>
                </Row>
                <Row> <Col  xs="12">
                      <p className="text-muted text-center">Version : UPSTH 1.0 Beta</p>
                      </Col></Row>
            </Container>
        </footer>
    );
}

export default Footer;
