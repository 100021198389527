import React from 'react';
import { Container, Jumbotron, Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap";
import { Link, } from 'react-router-dom';
function Home() {
    return <div >

        <Jumbotron>
            <Container>
                <h5 className="display-5">Roma's "Pizza Grill Curry" is a small privately owned family business. We offer fresh homemade italian and south asian (Nepali/Indian/Pakistani) cuisine in a casual family friendly atmosphere.</h5>
                <hr className="accent my-3 mt-5" />
                <Row className="lead">
                    <Col><Link to='/menu'>View Our Menu</Link></Col>
                    <Col className="text-left">
                        Location :<br />
                        <b> 113 Main St,</b><br />
                        <b>Beckley, WV 25801</b>
                    </Col>
                </Row>

            </Container>
        </Jumbotron>

        <Row className="lead text-center" >
            <Col><b>Hours :</b></Col>
            <Col xs="12" md="4" lg="6">
            
                <ListGroup flush>
                    <ListGroupItem>
                        <ListGroupItemHeading>Mon - Wed</ListGroupItemHeading>
                        <ListGroupItemText>
                           11:00 AM - 10:00 PM
                        </ListGroupItemText>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading>Thursday</ListGroupItemHeading>
                        <ListGroupItemText>
                           11:00 AM - 09:00 PM
                        </ListGroupItemText>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading>Fri - Sat</ListGroupItemHeading>
                        <ListGroupItemText>
                           11:00 AM - 11:00 PM
                        </ListGroupItemText>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading>Sunday</ListGroupItemHeading>
                        <ListGroupItemText>
                           Closed
                        </ListGroupItemText>
                    </ListGroupItem>
                </ListGroup>
            </Col>
        </Row>

        <hr className="my-2" />




    </div>
}

export default Home; 