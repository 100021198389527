import React from 'react';
import logo from '../../assets/img/Romas.PNG';
import { Link } from 'react-router-dom';
import classnames from "classnames";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faBars} from '@fortawesome/free-solid-svg-icons';
function LayoutNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 10 ||
        document.body.scrollTop > 10
      ) {
        setNavbarColor("navbar-light bg-white testNavbar");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("navbar-transparent bg-white");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate bg-white">
          
            <NavbarBrand
              href="/"
              title="EAT AT ROMA'S PIZZA GRILL CURRY"
            >
              <img src={logo} height="70px" className="text-center" alt="ROMA'S Pizza GRILL Curry" />
            </NavbarBrand>
            </div>
         
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <FontAwesomeIcon icon={faBars}/>
          </button>

            <Collapse
              className="justify-content-end"
              navbar
              isOpen={navbarCollapse}
            >
              <Nav navbar>
                <NavItem>
                  <NavLink>
                    <Link to="/" className="link-item" onClick={toggleNavbarCollapse}>Home</Link>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>
                    <Link to="/menu" className="link-item" onClick={toggleNavbarCollapse}>See Our Menu</Link>
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
        
      </Container>
    </Navbar>
  );
}

export default LayoutNavbar;