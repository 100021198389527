export const data = {
    category: [
        {
            id: "1", //unique key
            name: "Appetizer", //Main title
            helpText: "", // Detail if provided
            footnote: "", // footer note at the end of category
            remarks: "", // special note         
            menu: [
                {
                    name: "Cheese Fries",
                    price: "3.99",
                    description: "Fries baked with mozzarella cheese s/w house ranch",
                    remarks: "Add Bacon $2, Jalapenos $1",
                    
                },
                {
                    name: "Fried Pickle",
                    price: "5.99",
                    description: "Battered and fried served with house ranch",
                    remarks: "",
                    
                },
                {
                    name: "Onion Rings",
                    price: "2.99",
                    description: "Battered onion rings s/w house ranch",
                    remarks: "",
                    
                },
                {
                    name: "Mozzarella Sticks",
                    price: "5.99",
                    description: "Fried 6 pieces s/w marinara sauce",
                    remarks: "",
                    
                },
                {
                    name: "Breadsticks with Cheese",
                    price: "5.99",
                    description: "House made dough, garlic butter and mozzrella cheese s/w marinara sauce",
                    remarks: "",
                    
                },
                {
                    name: "Douby Douby Bread",
                    price: "4.99",
                    description: "Buttery garlic knots rolled with parmesan cheese s/w Marinara sauce",
                    remarks: "",
                    
                },
                {
                    name: "Fried Mushrooms",
                    price: "5.99",
                    description: "Battered whole mushrooms s/w house ranch",
                    remarks: "",
                    
                },
                {
                    name: "Samosa (2 PC)",
                    price: "4.99",
                    description: "Crispy turnovers stuffed with spiced potatoes and peas",
                    remarks: "",
                    
                },
                {
                    name: "Tofu Chilli",
                    price: "9.99",
                    description: "Stir-fried tofu cubes with onion and green pepper",
                    remarks: "",
                    
                },
                {
                    name: "MO:MO",
                    price: "9.99",
                    description: "Nepali style steamed dumplings filled with chicken and served with chutney",
                    remarks: "",
                    
                },
                {
                    name: "Chicken Chowmin",
                    price: "9.99",
                    description: "Nepali style stir-fried noodles mixed with vegetables and nepali herbs",
                    remarks: "(Subsitute tofu for Chicken no charge)",
                    
                },
                {
                    name: "Chicken Chilli",
                    price: "9.99",
                    description: "Stir-fried breaded chicken, onion and Green pepper",
                    remarks: "",
                    
                },
            ]
        },
        {
            id: "2", //unique key
            name: "Classic Wings or Boneless Wings", //Main title
            helpText: "House Marinated and Fried", // Detail if provided
            footnote: "HOT | MILD | HONEY BBQ | LEMON PEPPERS | GARLIC PARM | CURRY CILANTRO (Served with celery and house ranch or blue cheese)", // footer note at the end of category
            remarks: "5 pieces...$4.99 / 10 pieces...$8.99 / 20 pieces...$17.99", // special note         
            
        },
        {
            id: "3", //unique key
            name: "Chicken Tenders", //Main title
            helpText: "Chicken Tenders with Fries", // Detail if provided
            footnote: "", // footer note at the end of category
            remarks: "3 pieces...$7.99 | 6 pieces...$10.99", // special note         
           
        },
        {
            id: "4", //unique key
            name: "Salads", //Main title
            helpText: "", // Detail if provided
            footnote: "Add Chickend or Tuna for $3", // footer note at the end of category
            remarks: "Dressings: Blue Cheese, Ranch, Italian & Honey Mustard", // special note         
            menu: [
                {
                    name: "House Salad",
                    price: "5.99",
                    description: "Lettuce, Red Cabbage, Cucumbers, Red Onion, Tomatoes, Croutons, Gorgonzola Cheese",
                    remarks: "",
                    
                },
                {
                    name: "Antipasto",
                    price: "9.99",
                    description: "Lettuce, Red Cabbage, Cucumber, Red onion, Tomatoes, Black olive, Mozzarella Cheese, Croutons, Turkey and Ham",
                    remarks: "",
                    
                },
                {
                    name: "Greek Salad",
                    price: "9.99",
                    description: "Lettuce, Red Cabbage, Cucumber, Red onion, Tomatoes, Croutons, Banana peppers, Black Olives and Feta cheese",
                    remarks: "",
                    
                },
            ]
        },
        {
            id: "5", //unique key
            name: "Entrees", //Main title
            helpText: "(Cooked with curry spices and served with Rice/Naan)", // Detail if provided
            footnote: "Extras: Naan $2.99 / Rice $2.99", // footer note at the end of category
            remarks: "", // special note         
            menu: [
                {
                    name: "Palak Paneer",
                    price: "13.99",
                    description: "Cured cheese cubes cooked with spiach gravy",
                    remarks: "",
                    
                },
                {
                    name: "Chicken Palak",
                    price: "13.99",
                    description: "Boneless chicken breast cooked with spinach gravy",
                    remarks: "",
                    
                },
                {
                    name: "Chicken Tikka Masala",
                    price: "13.99",
                    description: "Marinated chicken cooked with tomato gravy and cream",
                    remarks: "",
                    
                },
                {
                    name: "Channa Masala",
                    price: "12.99",
                    description: "Garbanzo beans cooked with tomato base curry",
                    remarks: "",
                    
                },
                {
                    name: "Chicken Curry",
                    price: "13.99",
                    description: "Boneless chicken cubes cooked in tomato gravy",
                    remarks: "",
                    
                }
                ,
                {
                    name: "Mix Vegetable",
                    price: "12.99",
                    description: "Seasonal vegetables cooked in curry base tomato gravy",
                    remarks: "",
                    
                }
            ]
        },
        {
            id: "6", //unique key
            name: "Pizza", //Main title
            helpText: "(Our house made dough, house made pizza sauce, alfredo sauce, fresh chopped vegetables, marinated chicken)", // Detail if provided
            footnote: "Red onion, Green peppers, Mushrooms, Banana peppers, Jalapenos, Black Olives, Green Olives, Feta Cheese, Ham, Tomato, Pineapple, Spinach, Bacon, Sausage, Pepperoni", // footer note at the end of category
            remarks: "10 inch $6.99 / 14 inch $8.99 / 16 inch $10.99 Toppings : $0.99 / $1.49 / $1.99", // special note  
        },
        {
            id: "7", //unique key
            name: "Specialty Pizzas", //Main title
            helpText: "", // Detail if provided
            footnote: "Make any style pizza deep dish Sicilian add SM $2 and LG $4", // footer note at the end of category
            remarks: "", // special note         
            menu: [
                {
                    name: "Philly Steak",
                    price: "10.99",
                    description: "Pizza sauce, Red onion, Mushrooms, Green peppers, Serlion philly steak and Mozzarella cheese",
                    remarks: "14 inch $15.99 | 16 inch $17.99",
                    
                },
                {
                    name: "White Pizza",
                    price: "9.99",
                    description: "Garlic sauce, Ricotta garlic cloves, Marinated grilled chicken and Mozzarella cheese",
                    remarks: "14 inch $15.99 | 16 inch $17.99",
                    
                },
                {
                    name: "Alfredo Chicken",
                    price: "9.99",
                    description: "Alfredo sauce, Roasted garlic clovers, Marinated Grilled chicken and Mozzarella cheese",
                    remarks: "14 inch $15.99 | 16 inch $17.99",
                    
                },
                {
                    name: "Curry Tikka Masala",
                    price: "10.99",
                    description: "Creamy curry sauce, Marinated chicken, Red onion, Mozzarella cheese and Fresh cilantro",
                    remarks: "14 inch $16.99 | 16 inch $18.99",
                    
                },
                {
                    name: "Sriracha Chicken",
                    price: "10.99",
                    description: "Light sriracha sauce, Grilled chicken, Red onion, Jalapenos, Roasted garlic cloves, Mozzarella cheese, Swirl of house ranch with Fresh cilantro",
                    remarks: "14 inch $15.99 | 16 inch $17.99",
                    
                },
                {
                    name: "Tofu Love (Vegan)",
                    price: "9.99",
                    description: "Light BBQ sauce, Light sriracha, Red onion, Tofu cubes, Pineapple, Fresh cilantro (Add cheese on request, no charge)",
                    remarks: "14 inch $14.99 | 16 inch $16.99",
                    
                },
                {
                    name: "Buffalo Chicken",
                    price: "9.99",
                    description: "White sauce, Cripsy fried chicken, Red onion, Jalapenos, Mozzarella cheese, swirl of buffalo sauce s/w ranch",
                    remarks: "14 inch $14.99 | 16 inch $16.99",
                    
                },
                {
                    name: "Greek Pizza",
                    price: "9.99",
                    description: "Garlic sauce, Gyro meat, Feta cheese, Red onion, Black olives, Tomatoes and Mozzarella cheese",
                    remarks: "14 inch $14.99 | 16 inch $16.99",
                    
                },
                {
                    name: "Meat Lovers",
                    price: "9.99",
                    description: "Pizza sauce, Bacon, Ham, Pepperoni, Sausage and Mozzarella cheese",
                    remarks: "14 inch $15.99 | 16 inch $17.99",
                    
                },
                {
                    name: "Pizza Supreme",
                    price: "10.99",
                    description: "Pizza sauce, Red onion, Mushrooms, Green peppers, Banana peppers, Black olives, Pepperoni, Sausage and Mozzarella cheese",
                    remarks: "14 inch $15.99 | 16 inch $17.99",
                    
                },
                {
                    name: "Hawain",
                    price: "9.99",
                    description: "Pizza sauce, Grilled pineapple, Ham, Bacon and Mozzarella cheese",
                    remarks: "14 inch $13.99 | 16 inch $15.99",
                    
                },
                {
                    name: "Garden Veggie",
                    price: "9.99",
                    description: "Pizza sauce, Red onion, Mushroom, Green peppers, Banana peppers, Spinach, Black olives and Mozzarella cheese",
                    remarks: "14 inch $13.99 | 16 inch $17.99",
                    
                }
            ]
        },  
        {
            id: "8", //unique key
            name: "Pasta", //Main title
            helpText: "", // Detail if provided
            footnote: "Add : Side salad for $2, Extra Garlic Bread for $1", // footer note at the end of category
            remarks: "S/W side of Garlic Bread", // special note         
            menu: [
                {
                    name: "Spaghetti with Tomato Sauce",
                    price: "7.99",
                    description: "",
                    remarks: "Add meatbeals $1.99",
                    
                },
                {
                    name: "Baked Spaghetti",
                    price: "8.99",
                    description: "House parmesan sauce and Mozzarella cheese",
                    remarks: "Add meatballs $1.99",
                    
                },
                {
                    name: "ALfredo Chicken",
                    price: "10.99",
                    description: "Penne Pasta cooked in house made Alfredo sauce, Grilled Chicken and Mozzarella cheese",
                    remarks: "",
                    
                },
                {
                    name: "Lasagna",
                    price: "10.99",
                    description: "Handmade layers of pasta, Meat sauce, Ricotta and Mozzarella cheese",
                    remarks: "",
                    
                },
            ]
        }, 
        {
            id: "9", //unique key
            name: "Sandwiches | Wraps", //Main title
            helpText: "", // Detail if provided
            footnote: "", // footer note at the end of category
            remarks: "S/W French fries and Pickle spear", // special note         
            menu: [
                {
                    name: "Philly Steak",
                    price: "8.99",
                    description: "Philly steak, White American cheese, Lettuce, Tomato, Mayo",
                    remarks: "Add : Toping see pizza section $0.75",
                    
                },
                {
                    name: "Grilled Chicken",
                    price: "8.99",
                    description: "Marinated Chicken, Provolone cheese, Lettuce, Tomatoes, Mayo",
                    remarks: "Add Topping $0.75",
                    
                },
                {
                    name: "Curry Chicken",
                    price: "8.99",
                    description: "Curried Boneless chicken, Provolone cheese, Lettuce, Red onion, Fresh cilantro",
                    remarks: "",
                    
                },
                {
                    name: "Buffalo Chicken",
                    price: "8.99",
                    description: "Fried boneless chicken, Ranch, Buffalo sauce, Grilled Jalapenos, Red onions, Bacon, Provolone cheese",
                    remarks: "",
                    
                },
                {
                    name: "Super SUB (Hot/Cold)",
                    price: "8.99",
                    description: "Turkey, Ham, Pepperoni, Onion, Banana peppers, Provolone cheese, Lettuce, Tomatoes, Mayo",
                    remarks: "",
                    
                },
                {
                    name: "Chicken Parm SUB",
                    price: "8.99",
                    description: "Fried chicken, Parmesan sauce, Mozzarella cheese",
                    remarks: "",
                    
                },
                {
                    name: "Meatball Parmesan SUB",
                    price: "8.99",
                    description: "Beef Meatball, Parmesan sauce, Mozzarella Cheese",
                    remarks: "",
                    
                },
                {
                    name: "Gyro",
                    price: "8.99",
                    description: "Gyro meat on Pita with Tzatziki sauce, Onion, Tomato, Lettuce",
                    remarks: "",
                    
                }
            ]
        },
        {
        id: "10", //unique key
        name: "Lunch", //Main title
        helpText: "", // Detail if provided
        footnote: "", // footer note at the end of category
        remarks: "Monday - Friday (11:00 AM - 3:00 PM)", // special note         
        menu: [
            {
                name: "Pizza",
                price: "6.99",
                description: "10 inch pepperoni and cheese pizza with can of soda",
                remarks: "",
                
            },
            {
                name: "Pepperoni roll",
                price: "6.99",
                description: "S/W marinara sauce and can of soda",
                remarks: "",
                
            },
            {
                name: "Cheese Burger",
                price: "8.99",
                description: "Beef Patty, Chedder cheese, House thousand island dressing, Lettuce, Tomato, Onion s/w Fries, Pickle spear",
                remarks: "Pasta salad or Soup of the day",
                
            },
            {
                name: "Chicken Salad (Cold)",
                price: "7.99",
                description: "House made chicken salad s/w and fies",
                remarks: "Pasta salad or Soup of the day",
                
            },
            {
                name: "Curry Chicken",
                price: "7.99",
                description: "6 inch sub curried chicken with red onion, lettuce, cilantro",
                remarks: "Pasta salad or Soup of the day",
                
            },
            {
                name: "Ham Turkey",
                price: "7.99",
                description: "6 inch (hot or cold) Onion, American cheese, Lettus, Tomato s/w fries, pickle spear",
                remarks: "Pasta salad or Soup of the day",
               
            },
            {
                name: "Curry Platters",
                price: "8.99",
                description: "6 inch sub curried chicken with red onion, lettuce, cilantro",
                remarks: "Pasta salad or Soup of the day",
                options: "TODO",
            }

        ]
    },
    {
        id: "11", //unique key
        name: "Strombolis & Calzones", //Main title
        helpText: "Brushed with garlic s/w marinara sauce", // Detail if provided
        footnote: "", // footer note at the end of category
        remarks: "Extra topping see prize section small $0.99, large $1.99", // special note         
        menu: [
            {
                name: "Steak Stromboli",
                price: "9.99",
                description: "Grilled philly steak, Red onion, Mushrooms, Green peppers, Mozzarella cheese",
                remarks: "Large size - $17.99",
                
            },
            {
                name: "Grilled Chicken",
                price: "9.99",
                description: "Grilled philly steak, Red onion, Mushrooms, Green peppers, Mozzarella cheese",
                remarks: "Large size - $17.99",
                
            },
            {
                name: "Pepperoni Calzone",
                price: "7.99",
                description: "Pepproni Ricotta cheese, Mozzarella cheese",
                remarks: "Large size - $14.99",
                
            },
            {
                name: "Meat Lover",
                price: "9.99",
                description: "Bacon, Ham, Pepperoni, Sausage, Mozzarella cheese",
                remarks: "Large size - $17.99",
                
            },
            {
                name: "Veggie Calzone",
                price: "8.99",
                description: "Ricotta Red Onion, Green peppers, Banana peppers, Black olives, Spinach, Mushrooms, Mozzarella cheese",
                remarks: "Large size - $17.99",
                
            }
        ]
    },
    {
        id: "12", //unique key
        name: "Pizza Rolls", //Main title
        helpText: "", // Detail if provided
        footnote: "", // footer note at the end of category
        remarks: "", // special note         
        menu: [
            {
                name: "Cheese Rolls",
                price: "5.99",
                description: "",
                remarks: "Add topping $0.79 see pizza section",
                
            }
        ]
    },{
        id: "13", //unique key
        name: "Burgers", //Main title
        helpText: "", // Detail if provided
        footnote: "* Add Topping or Cheese to any Burger", // footer note at the end of category
        remarks: "All burger patties are hand made with special seasoning, s/w french fries and pickle spear", // special note         
        menu: [
            {
                name: "Classic Cheese Burger",
                price: "8.99",
                description: "Ground beef, Cheddar cheese, Grilled onion, Lettuce, Tomato, Home made thousand island dressing on a toasted bun",
                remarks: "",
                
            },
            {
                name: "Veggie Bean",
                price: "8.99",
                description: "Black bean quinoa patty, Provolon cheese, Lettuce, Tomato, Onion, Home made thousand island dressing",
                remarks: "",
                
            },
            {
                name: "Double Donut",
                price: "8.99",
                description: "Glazed donut, Beef patty, Cripsy chopped bacon, Yellow cheddar and provolone cheese",
                remarks: "",
                
            },
            {
                name: "Delta 554",
                price: "14.99",
                description: "Double Beef patty, Cheedar, Provolone, American cheese, Bacon, Cripsy fried onion, Home made thousand island dressing on a toasted bun",
                remarks: "S/W side of warm special cheese sauce",
                
            },
        ]
    },{
        id: "14", //unique key
        name: "Drinks", //Main title
        helpText: "", // Detail if provided
        footnote: "Pespi, Diet Pepsi, Mountain Dew, Dr. Pepper, Mist, Lemonade, Sweet Tea", // footer note at the end of category
        remarks: "", // special note         
        menu: [
            {
                name: "Chai Tea",
                price: "1.00",
                description: "",
                remarks: "",
                
            },
            {
                name: "Mango Lassi",
                price: "2.99",
                description: "",
                remarks: "",
                
            },
            {
                name: "Soda Cans",
                price: "1.00",
                description: "",
                remarks: "",
                
            },
            {
                name: "Bottle",
                price: "1.69",
                description: "",
                remarks: "",
                
            },
            {
                name: "2 Litres",
                price: "2.99",
                description: "",
                remarks: "",
                
            },
            {
                name: "Fountain Drinks",
                price: "1.99",
                description: "",
                remarks: "",
                
            }
        ]
    },
    {
        id: "15", //unique key
        name: "KIDS MENU", //Main title
        helpText: "", // Detail if provided
        footnote: "", // footer note at the end of category
        remarks: "", // special note         
        menu: [
            {
                name: "Ham N Cheese",
                price: "5.99",
                description: "Grilled ham and cheese with french fries",
                remarks: "",
                
            },
            {
                name: "Spaghetti",
                price: "5.99",
                description: "Spaghetti with Tomato sauce and Garlic Bread",
                remarks: "",
                
            },
            {
                name: "Tenders",
                price: "5.99",
                description: "Chicken tenders and French Fries",
                remarks: "",
                
            },
        ]
    },
    {
        id: "16", //unique key
        name: "Desserts", //Main title
        helpText: "", // Detail if provided
        footnote: "", // footer note at the end of category
        remarks: "", // special note         
        menu: [
            {
                name: "Cheese Cake",
                price: "2.99",
                description: "",
                remarks: "",
                
            },
            {
                name: "Strawberry Cheese Cake",
                price: "2.99",
                description: "",
                remarks: "",
                
            },
            {
                name: "Sweety Sweety Bread",
                price: "4.99",
                description: "",
                remarks: "",
                
            },
            {
                name: "Nutella Pie",
                price: "3.99",
                description: "",
                remarks: "",
                
            },
        ]
    },
]
};

//TEMPLATE
/*
    {
        id: "", //unique key
        name: "", //Main title
        helpText: "", // Detail if provided
        footnote: "", // footer note at the end of category
        remarks: "", // special note         
        menu: [
            {
                name: "",
                price: "",
                description: "",
                remarks: "",
                
            },
            {
                name: "",
                price: "",
                description: "",
                remarks: "",
                
            },
            {
                name: "",
                price: "",
                description: "",
                remarks: "",
                
            },
            {
                name: "",
                price: "",
                description: "",
                remarks: "",
                
            },
            {
                name: "",
                price: "",
                description: "",
                remarks: "",
                
            }
        ]
    },
    */