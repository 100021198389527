import React from 'react';
import {
    Card,
    CardBody,
    CardGroup,
    Col,
    Row,
} from "reactstrap";


export default function MenuItem(props) {


    return (
        <>        
        <Row xs="1" sm="2" md="2" lg="2" className="accent my-3">
        {CreateMenuItems()}
        </Row>
        </>
    );

    function CreateMenuItems() {

        const menus = props.data.map((x, i) => {
            // eslint-disable-next-line no-unused-expressions
            return <>
                <Col id={i} sm={{ size: 'auto' }}>
                    <CardGroup>
                    <Card className="mb-2">
                        <CardBody> 
                            <span className="float-right font-weight-bold">${x.price}</span>
                            <h5 className="text-left text-success">{x.name}</h5>
                            <p className="small">{x.description}</p>
                            <i className="small">{x.remarks}</i>
                        </CardBody>
                    </Card>
                    </CardGroup>
                </Col>
            </>
        }
        );
        return menus;

    }

}

